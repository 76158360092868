import React from 'react';
import Moment from 'moment';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import LegalLayout from 'components/legal-layout';
import P69466 from '@youship/legal/projects/p69466';
import SEO from 'components/seo';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const bannerImage = 'create-account.png';

const bannerButtons = [
  {
    external: true,
    linkProps: {
      to: `${WEB_APP_URL}/register`
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'Sign up now'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'Contact us'
  }
];

const lastModifiedDate = Moment().format('MMMM DD, YYYY');

const SafyConciergePage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="SafyConcierge" />
    <LegalLayout
      lastModifiedDate={lastModifiedDate}
      title="SafyConcierge"
    >
      <P69466 />
    </LegalLayout>
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="Follow your order and communicate with the carrier in real time, anywhere."
      title="Create an account"
    />
  </Layout>
);

export default SafyConciergePage;
