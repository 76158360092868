import React from 'react';
import Link from 'utils/localized-link';

import Button from '@youship/components/objects/button';
import Image from 'components/image';


const image1 = 'safy-concierge/image1.png';
const image2 = 'safy-concierge/image2.png';
const image3 = 'safy-concierge/image3.png';

const P69466 = () => (
  <>
    <div>
      <h3>
        Designação do projeto
      </h3>
      <p>
        <b>
          SafyConcierge
        </b>
      </p>
    </div>
    <div>
      <h3>
        Código do projeto
      </h3>
      <p>
        CENTRO-01-02B7-FEDER-069466
      </p>
    </div>
    <div>
      <h3>
        Objetivo principal
      </h3>
      <p>
        Reforçar a investigação, o desenvolvimento tecnológico e a inovação
      </p>
    </div>
    <div>
      <h3>
        Região de intervenção
      </h3>
      <p>
        Programa Operacional Regional do Centro
      </p>
    </div>
    <div>
      <h3>
        Entidade beneficiária
      </h3>
      <p>
        SHIPNOW TECHNOLOGIES, UNIPESSOAL LDA
      </p>
    </div>
    <div>
      <h3>
        Data de aprovação
      </h3>
      <p>
        15-10-2020
      </p>
    </div>
    <div>
      <h3>
        Data de início
      </h3>
      <p>
        01-09-2020
      </p>
    </div>
    <div>
      <h3>
        Data de conclusão
      </h3>
      <p>
        31-05-2021
      </p>
    </div>
    <div>
      <h3>
        Custo total elegível
      </h3>
      <p>
        149.206,25 EUR
      </p>
    </div>
    <div>
      <h3>
        Apoio financeiro da União Europeia
      </h3>
      <p>
        FEDER 119.365,00 EUR
      </p>
    </div>
    <div>

      <h3>
        Descrição e Objetivos do Projeto:
      </h3>
      <p>
        O projeto SafyConcierge tem como objetivo utilizar um robô móvel para prestar serviços de concierge em ambiente de
        cuidados de saúde, automatizando os processos e logística interna e por outro reduzindo o contato social entre profissionais e/ou pacientes.
      </p>
      <br />
      <section className="col-12 col-md-4 p-0">
        <Image
          alt="SafyConcierge"
          src={image1}
        />
      </section>
      <br />
      <br />
      <p>
        Assim propomos, a partir de uma plataforma de logística e de uma plataforma robótica existentes, fazer as devidas
        adaptações e disponibilizar ao mercado uma solução integrada que permita:
      </p>
      <br />
      <ul>
        <li>
          Gerir os recursos da organização: fluxos, disponibilidades e afetações;
        </li>
        <li>
          Automatizar a logística interna usando um robô móvel;
        </li>
        <li>
          Telepresença móvel;
        </li>
      </ul>
      <br />
      <section className="col-12 col-md-4 p-0">
        <Image
          alt="SafyConcierge"
          src={image2}
        />
      </section>
      <br />
      <br />
      <p>
        A plataforma SafyConcierge, não só pode fazer a gestão de equipamentos e inventário essenciais a um hospital ou uma
        instituição (exemplo lares ou cuidados paliativos), como evita o contacto social, uma vez que o robot substituiu a
        deslocação de um funcionário para realizar certas tarefas como distribuir medicação, entregar correspondência,
        pequenos objetos e alimentação, e acompanhamento dos pacientes via telepresença.
      </p>
      <br />
      <section className="col-12 col-md-4 p-0">
        <Image
          alt="SafyConcierge"
          src={image3}
        />
      </section>
      <br />
      <br />
      <Button
        context="primary"
        external
        linkComponent={Link}
        linkProps={{ to: 'https://www.you-ship.com/site2/pt/safyconcierge' }}
        noArrow
        text="Consultar min-site"
      />
    </div>
  </>
);

export default P69466;
